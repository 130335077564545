import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const HorizontalPatternTop = ({ className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span className={clsx("icon block", _className)}>
      <m.svg
        width="588"
        height="493"
        viewBox="0 0 588 493"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M317.297 140.546H480.324C486.759 140.546 491.994 145.726 492.062 152.161C492.539 197.28 456.095 234.112 410.973 234.112H330.798C317.645 234.112 307.021 223.376 307.16 210.223L307.798 149.945C307.853 144.738 312.09 140.546 317.297 140.546Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M215.436 183.383V257.165C215.436 262.412 211.183 266.665 205.936 266.665H133.907C127.259 266.665 121.87 261.276 121.87 254.628V253.28C121.87 208.141 158.462 171.549 203.601 171.549C210.137 171.549 215.436 176.847 215.436 183.383Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M345.659 180.891V224.154C345.659 272.462 306.498 311.623 258.19 311.623C254.823 311.623 252.093 308.893 252.093 305.525V181.049C252.093 175.802 256.346 171.549 261.593 171.549H336.316C341.476 171.549 345.659 175.731 345.659 180.891Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M181.491 109.538L131.827 109.538C83.5191 109.538 44.358 148.699 44.358 197.006C44.358 200.374 47.0881 203.104 50.4557 203.104L174.932 203.104C180.179 203.104 184.432 198.851 184.432 193.604L184.432 112.479C184.432 110.855 183.115 109.538 181.491 109.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M8.14822 492.072H6.70609C3.27872 492.072 0.500298 489.294 0.500298 485.866L0.500298 408.006C0.500298 402.759 4.75359 398.506 10.0003 398.506L89.5191 398.506C92.8867 398.506 95.6167 401.236 95.6167 404.604C95.6167 452.911 56.4557 492.072 8.14822 492.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M59.1218 360.072H57.6797C54.2524 360.072 51.4739 357.294 51.4739 353.866V276.006C51.4739 270.759 55.7272 266.506 60.9739 266.506H140.493C143.86 266.506 146.59 269.236 146.59 272.604C146.59 320.911 107.429 360.072 59.1218 360.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M500.032 109.538H498.59C495.163 109.538 492.384 112.317 492.384 115.744V193.604C492.384 198.851 496.637 203.104 501.884 203.104H581.403C584.77 203.104 587.5 200.374 587.5 197.007C587.5 148.699 548.34 109.538 500.032 109.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M298.954 172.1H224.387C219.141 172.1 214.887 167.847 214.887 162.6V88.0338C214.887 82.7871 219.141 78.5338 224.387 78.5338H298.954C304.2 78.5338 308.454 82.7871 308.454 88.0338V162.6C308.454 167.847 304.2 172.1 298.954 172.1Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M398.801 131.232V87.9685C398.801 39.661 437.962 0.5 486.27 0.5C489.638 0.5 492.368 3.23001 492.368 6.59764V131.074C492.368 136.321 488.114 140.574 482.868 140.574H408.144C402.984 140.574 398.801 136.391 398.801 131.232Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M96.066 182.6L96.066 390.342C96.066 394.813 92.4409 398.439 87.9691 398.439C40.7658 398.439 2.49991 360.173 2.49991 312.969L2.49991 182.6C2.49991 177.353 6.7532 173.1 11.9999 173.1H86.566C91.8127 173.1 96.066 177.353 96.066 182.6Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

HorizontalPatternTop.defaultProps = {};

export default HorizontalPatternTop;
