import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const HorizontalPatternBottom = ({ className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span className={clsx("icon block", _className)}>
      <m.svg
        width="744"
        height="400"
        viewBox="0 0 744 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M427.704 259.026H264.678C258.243 259.026 253.007 253.846 252.939 247.411C252.462 202.292 288.906 165.46 334.029 165.46H414.204C427.357 165.46 437.98 176.197 437.841 189.349L437.204 249.627C437.149 254.834 432.912 259.026 427.704 259.026Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="267.57"
          y="188.944"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 267.57 188.944)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M529.565 216.189V142.407C529.565 137.16 533.819 132.907 539.065 132.907H611.095C617.742 132.907 623.132 138.296 623.132 144.944V146.292C623.132 191.431 586.539 228.024 541.4 228.024C534.864 228.024 529.565 222.725 529.565 216.189Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M157.066 154.342V197.606C157.066 245.913 117.905 285.074 69.5974 285.074C66.2298 285.074 63.4998 282.344 63.4998 278.976L63.4998 154.5C63.4998 149.253 67.7531 145 72.9998 145L147.724 145C152.883 145 157.066 149.183 157.066 154.342Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="0.5"
          y="113"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M399.343 218.681V175.418C399.343 127.111 438.504 87.9495 486.811 87.9495C490.179 87.9495 492.909 90.6795 492.909 94.0471V218.524C492.909 223.77 488.656 228.024 483.409 228.024H408.685C403.525 228.024 399.343 223.841 399.343 218.681Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M563.51 290.034H613.175C661.482 290.034 700.643 250.873 700.643 202.566C700.643 199.198 697.913 196.468 694.546 196.468H570.069C564.823 196.468 560.569 200.721 560.569 205.968V287.093C560.569 288.718 561.886 290.034 563.51 290.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M295.88 59.5H297.322C300.749 59.5 303.528 62.2784 303.528 65.7058V143.566C303.528 148.813 299.274 153.066 294.028 153.066H214.509C211.141 153.066 208.411 150.336 208.411 146.968C208.411 98.661 247.572 59.5 295.88 59.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M685.88 39.5H687.322C690.749 39.5 693.528 42.2784 693.528 45.7058V123.566C693.528 128.813 689.274 133.066 684.028 133.066H604.509C601.141 133.066 598.411 130.336 598.411 126.968C598.411 78.661 637.572 39.5 685.88 39.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M244.969 290.034H246.412C249.839 290.034 252.617 287.256 252.617 283.828V205.968C252.617 200.721 248.364 196.468 243.117 196.468H163.599C160.231 196.468 157.501 199.198 157.501 202.566C157.501 250.873 196.662 290.034 244.969 290.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M446.048 227.472H520.614C525.861 227.472 530.114 231.726 530.114 236.972V311.538C530.114 316.785 525.861 321.038 520.614 321.038H446.048C440.801 321.038 436.548 316.785 436.548 311.538V236.972C436.548 231.726 440.801 227.472 446.048 227.472Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M346.2 268.341V311.604C346.2 359.911 307.039 399.072 258.732 399.072C255.364 399.072 252.634 396.342 252.634 392.975V268.498C252.634 263.251 256.887 258.998 262.134 258.998H336.858C342.017 258.998 346.2 263.181 346.2 268.341Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M648.935 216.972V9.23059C648.935 4.75879 652.561 1.13368 657.032 1.13368C704.236 1.13368 742.502 39.3996 742.502 86.6029V216.972C742.502 222.219 738.248 226.472 733.002 226.472H658.435C653.189 226.472 648.935 222.219 648.935 216.972Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

HorizontalPatternBottom.defaultProps = {};

export default HorizontalPatternBottom;
